import { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Profile } from '../../EditProfile/ProfileForm';
import Patterns from '../../../../../../../utils/pattern/patterns';
import { countriesList as countries } from '../../../../../../../services/Dictionary';
import { redirect, renderHTMLString } from '../../../../../../../helpers';
import Auth from '../../../../../../../services/AuthService';
import { selectTenantConfig } from '../../../../../../../store/features/tenantConfig';
import s from './RegisterForm.module.scss';
import Form from '../../shared/Form';
import TextField from '../../shared/TextField';
import SelectField from '../../shared/SelectField';
import PasswordField from '../../shared/PasswordField';
import PasswordStrengthBar from '../../shared/PasswordStrengthBar';
import HelperText from '../../shared/HelperText';
import InputControl from '../../shared/InputControl';
import Button from '../../shared/Button';

const termsText =
  'By creating an account you agree to Informa\'s <a href="https://www.informa.com/generic-content/terms--conditions/" target="_blank" rel="noreferrer">Terms of use</a> and <a href="https://informa.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy policy</a>.';

const errors = {
  DEFAULT: 'Something went wrong. Please try again later.',
  USER_EXISTS:
    'There was a problem with this sign up. If you already have an account, please check your email',
};
const REQUIRED = 'Required';
const USER_EXISTS_PATTERN = 'There already exists a user with the username';

export function validateCharacters(value: string) {
  return !value || Patterns.isValidChar(value) ? true : 'Invalid characters';
}

interface User extends Profile {
  password: string;
}

const DEFAULT_VALUES: Partial<User> = {
  forename: '',
  surname: '',
  companyName: '',
  jobTitle: '',
  country: '',
  email: '',
  telephone: '',
  password: '',
};

export default function RegisterForm() {
  const form = useForm<User>({
    defaultValues: DEFAULT_VALUES,
  });
  const { id: tenantId } = useSelector(selectTenantConfig);
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const {
    reset,
    formState: { isSubmitting, isSubmitted },
  } = form;
  const passwordValue = form.watch('password');

  useEffect(() => {
    async function getGuest(guestId: string) {
      try {
        const response = await Auth.fetchGuestDetails(guestId);
        const data = await response.json();

        if (data.email) {
          const { acceptTerms, ...guestData } = data;

          reset({ ...DEFAULT_VALUES, ...guestData });
        }
      } catch (e) {
        console.error(e);
      }
    }

    const { u: userId } = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });

    if (userId) {
      void getGuest(userId as string);
    }
  }, [reset]);

  const handleSubmit: SubmitHandler<User> = useCallback(
    async (values) => {
      setError('');

      const response = await Auth.createAccount(
        values,
        values.email,
        values.password,
        tenantId,
      );
      const { error: responseError } = await response.json();

      if (response.status === 200) {
        const u = btoa(values.email);
        const url = `/account/register/success/?username=${u}`;
        redirect(url);
        return;
      }

      let errorMessage;

      if (responseError) {
        if (responseError.indexOf(USER_EXISTS_PATTERN) !== -1) {
          errorMessage = errors.USER_EXISTS;
        } else {
          errorMessage = responseError;
        }
      }

      setError(errorMessage || errors.DEFAULT);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [tenantId],
  );

  const countryOptions = useMemo(() => {
    return Object.keys(countries).map((countryCode) => {
      return {
        value: countryCode,
        label: t(countries[countryCode]),
      };
    });
  }, [t]);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <InputControl>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="forename"
              label="First Name *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="surname"
              label="Last Name *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
        </div>
      </InputControl>
      <InputControl>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="companyName"
              label="Company *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="jobTitle"
              label="Job Title *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
        </div>
      </InputControl>
      <InputControl>
        <SelectField
          name="country"
          options={countryOptions}
          label="Country / Region *"
          blankOption={<option value="">Please select</option>}
          validation={{ required: REQUIRED }}
        />
      </InputControl>
      <InputControl>
        <TextField
          type="email"
          name="email"
          label="Business Email *"
          validation={{
            required: REQUIRED,
            validate: {
              isValid(value) {
                return !value || Patterns.isEmail(value)
                  ? true
                  : 'Please enter a valid email address';
              },
            },
          }}
        />
      </InputControl>
      <InputControl>
        <PasswordField
          name="password"
          label="Password *"
          validation={{
            required: REQUIRED,
            minLength: {
              value: Patterns.passwordLength,
              message: `Your password must be at least ${Patterns.passwordLength} characters long`,
            },
            validate: {
              isValid(value) {
                return !value || Patterns.isPassword(value)
                  ? true
                  : 'Password must have at least 1 upper case letter, 1 lowercase letter and 1 number';
              },
            },
          }}
        />
      </InputControl>
      <PasswordStrengthBar
        password={passwordValue}
        showInvalid={isSubmitted}
        className={s.strengthBar}
      />
      <HelperText className={s.termsText}>
        {renderHTMLString(termsText)}
      </HelperText>
      {error && (
        <HelperText color="danger" className={s.errorHelperText}>
          {error}
        </HelperText>
      )}
      <Button
        type="submit"
        color="accent"
        disabled={isSubmitting}
        className={s.submitButton}
      >
        Create account
      </Button>
    </Form>
  );
}
