import { createSelector } from '@reduxjs/toolkit';
import { UploadedImage } from '../../../types';

export interface LeadGen {
  teaser: {
    articleTitle: string;
    teaserHeading?: string;
    teaserBody?: string;
    thumbnail?: UploadedImage;
  };
}

export const selectLeadGenForm = createSelector(
  [(state) => state.leadGenForm.data as LeadGen],
  (data) => data,
);

export const selectLeadGenFormLoaded = createSelector(
  [(state) => state.leadGenForm.loaded as boolean],
  (loaded) => loaded,
);

export const selectLeadGenGateType = createSelector(
  [(state) => state.leadGenForm.leadGenType as 'agenda' | 'article'],
  (type) => type,
);

export const selectLeadGenStateOptions = createSelector(
  [(state) => state.leadGenForm?.data?.form?.stateOptions],
  (stateOptions) => stateOptions,
);
